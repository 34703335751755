import { AnalyticsEvent } from '@/types';

const getDeviceType = () => {
  const { userAgent } = navigator;

  if (/mobile/i.test(userAgent)) {
    return 'Mobile';
  }
  if (/tablet|ipad|playbook|silk/i.test(userAgent)) {
    return 'Tablet';
  }
  return 'Desktop';
};

const getDate = () => {
  const date = new Date();
  const { year, month, day } = {
    year: date.getFullYear().toString(),
    month: (date.getMonth() + 1).toString().padStart(2, '0'),
    day: date.getDate().toString().padStart(2, '0'),
  };
  return `${year}-${month}-${day}`;
};

export const handleActionTracking = ({
  eventName,
  conversionLabel,
  eventLabel,
  pageTarget,
  username = 'Guest',
  zip,
  customProperties,
}: AnalyticsEvent) => {
  const fullUrl = new URL(window.location.href);
  const urlWithoutDomain = `${fullUrl.pathname}${fullUrl.search}${fullUrl.hash}`;
  const date = getDate();
  const deviceType = getDeviceType();

  if (window.gtag && conversionLabel) {
    window.gtag('event', eventName, {
      send_to: [
        `${process.env.NEXT_PUBLIC_GADS_ID}/${conversionLabel}`,
        process.env.NEXT_PUBLIC_GTM_ID,
      ],
      event_label: eventLabel,
      user_info: `${username} ${date} ${deviceType} ${zip?.city} ${zip?.country}`,
      page_location: urlWithoutDomain,
      page_target: pageTarget || urlWithoutDomain,
      ...customProperties,
    });
  }
};

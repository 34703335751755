import { FC, PropsWithChildren, createContext, useContext } from 'react';
import {
  Address,
  useCustomZip,
  usePreferredZip,
  useUserZip,
} from '@/hooks/useUserZip';

interface UserZipType {
  userZip: Address | undefined;
  loading: boolean;
  error: boolean;
}

interface CustomZipType {
  customZip: string | undefined;
  customAddress: string | undefined;
  setCustomAddress: (address: string) => void;
  setCustomZip: (postal: string) => void;
  clearCustomZip: () => void;
}

interface ZipContextValue extends UserZipType, CustomZipType {
  usePreferedZip: () => string | undefined;
}

const ZipContext = createContext<ZipContextValue | null>(null);

export const ZipProvider: FC<PropsWithChildren> = ({ children }) => {
  const { zip: userZip, loading, error } = useUserZip();
  const customZipObj = useCustomZip();
  const {
    setCustomZip,
    clearCustomZip,
    setCustomAddress,
    zip: customZip,
    address: customAddress,
    clearCustomAddress,
  } = customZipObj;

  const usePreferedZip = usePreferredZip;

  const contextValue = {
    userZip,
    loading,
    error,
    customZip,
    customAddress,
    setCustomAddress,
    clearCustomAddress,
    setCustomZip,
    clearCustomZip,
    usePreferedZip,
  };

  return (
    <ZipContext.Provider value={contextValue}>{children}</ZipContext.Provider>
  );
};

export const useZipContext = () => {
  const context = useContext(ZipContext);
  if (!context) {
    throw new Error('useZipContext must be used within a ZipProvider');
  }
  return context;
};

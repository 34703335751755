import React, {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { useAuth } from '@/hooks/useAuth';
import { AnalyticsEvent } from '@/types';
import { useUserZip } from '@/hooks/useUserZip';
import { handleActionTracking } from '@/lib/handleActionTracking';

export const AnalyticsContext = createContext<{
  handleActionTracking: (event: AnalyticsEvent) => void;
}>({ handleActionTracking: () => {} });

export const useAnalytics = () => useContext(AnalyticsContext);

export const AnalyticsProvider: FC<PropsWithChildren> = ({ children }) => {
  const { user } = useAuth();
  const { zip } = useUserZip();

  const memoizedHandleActionTracking = useCallback(
    (event: AnalyticsEvent) => {
      handleActionTracking({ username: user?.evId, zip, ...event });
    },
    [user, zip]
  );

  const value = useMemo(
    () => ({
      handleActionTracking: memoizedHandleActionTracking,
    }),
    [memoizedHandleActionTracking]
  );

  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  );
};

import React from 'react';
import Wrapper from '@/components/atoms/legacy/Wrapper';
import { Footer } from '@/components/organisms/Footer';
import { useRouter } from 'next/router';
import FunnelFooter from '@/components/organisms/FunnelFooter/FunnelFooter';
import styles from './FooterTemplate.module.scss';

export const FooterTemplate: React.FC = () => {
  const router = useRouter();
  const { pathname } = router;
  const showFunnelFooter =
    pathname.includes('/[...slug]') || pathname.includes('/settings');
  return (
    <Wrapper
      className={`bg-galaxyBlue relative bottom-0 w-full ${styles.footerWrapper}`}
    >
      {showFunnelFooter ? <FunnelFooter /> : <Footer id="main-footer" />}
    </Wrapper>
  );
};

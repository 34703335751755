import { ImageLoaderProps } from 'next/image';

export const cloudfrontLoader = ({ src, width }: ImageLoaderProps) => {
  const srcUrl = new URL(src);

  const cfDomain = 'https://dim1d4ldn9woz.cloudfront.net';
  const bucketName = srcUrl.host.split('.')[0];

  const url = `${cfDomain}${srcUrl.pathname}?original_s3_bucket_name=${bucketName}&width=${width}`;

  return url;
};

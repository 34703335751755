import Image from '@/components/atoms/Image';
import { UserIcon } from '@heroicons/react/24/outline';

interface AvatarProps {
  size: 'small' | 'medium';
  /** 2 letters, like a user's initials. If it's falsy, render a default "User" icon */
  fallback?: string;
  imageIconSrc?: string;
}

export const Avatar: React.FC<AvatarProps> = ({
  size,
  fallback,
  imageIconSrc,
}) => {
  const containerSizeToPx = size === 'small' ? 32 : 64;
  const fallbackIconSize = size === 'small' ? 16 : 32;

  const renderContent = () => {
    if (imageIconSrc) {
      return (
        <Image
          aria-label="User avatar image"
          src={imageIconSrc}
          alt="User avatar"
          className="!relative rounded-full"
          fill
        />
      );
    }
    if (fallback) {
      return (
        <span
          className={`text-title-20px text-grey ${
            size === 'small' ? 'text-xs' : 'text-xl'
          }`}
        >
          {fallback}
        </span>
      );
    }
    return (
      <UserIcon
        aria-label="User icon"
        stroke="#010044"
        width={fallbackIconSize}
        height={fallbackIconSize}
      />
    );
  };

  return (
    <div
      className={`flex items-center justify-center rounded-full bg-background-cloud w-[${containerSizeToPx}px] h-[${containerSizeToPx}px]`}
    >
      {renderContent()}
    </div>
  );
};

import Logo from '@/components/molecules/Logo';
import Link, { LinkVariants } from '@/components/atoms/legacy/Link';
import { GOOGLE_TRACK_INFO } from '@/lib/constants';
import Button, { ButtonVariants } from '@/components/atoms/Button/Button';
import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/solid';

export const footerLinks = [
  {
    text: 'Terms & conditions',
    href: '/terms_and_conditions',
    analyticsEvent: {
      ...GOOGLE_TRACK_INFO.termsAndConditionsFooterLink,
      pageTarget: '/terms_and_conditions',
    },
  },
  {
    text: 'Manage cookies',
    href: '/cookie_policy',
    analyticsEvent: {
      ...GOOGLE_TRACK_INFO.manageCookiesFooterLink,
      pageTarget: '/cookie_policy',
    },
  },
  {
    text: 'Privacy policy',
    href: '/privacy_policy',
    analyticsEvent: {
      ...GOOGLE_TRACK_INFO.privacyPolicyFooterLink,
      pageTarget: '/privacy_policy',
    },
  },
  {
    text: 'Site map',
    href: '/sitemap',
  },
];

const FunnelFooter = ({ className = '', ...props }) => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={`relative py-xl text-white ${className}`} {...props}>
      <section className="flex w-full justify-between">
        <section className="flex basis-5/6 flex-col items-start justify-between gap-xl l:flex-row l:items-center">
          <Link href="/" aria-label="home">
            <Logo className="relative w-[80px] m:w-[115px]" reverse />
          </Link>
          <section className="flex flex-col items-start gap-l l:flex-row l:items-center">
            <nav className="flex flex-col gap-m m:flex-row m:gap-[18px]">
              {footerLinks.map(({ text, href, analyticsEvent }) => (
                <Link
                  aria-label={`link to ${text}`}
                  className="!px-0 text-body2Light text-neutral-200 hover:text-brandElectric lg:overflow-hidden lg:whitespace-nowrap"
                  key={text}
                  href={href}
                  variant={LinkVariants.Default}
                  analyticsEvent={analyticsEvent}
                >
                  {text}
                </Link>
              ))}
            </nav>
            <p className="hidden text-body2Light text-neutral-200 l:block l:border-l-[1px] l:border-l-neutral-200 l:pl-l lg:overflow-hidden lg:whitespace-nowrap">
              © {currentYear} EV.com Inc. All rights reserved
            </p>
          </section>
        </section>
        <section>
          <Button
            aria-label="Send us feedback"
            variant={ButtonVariants.Secondary}
            theme="dark"
            size="small"
            icon={{
              icon: <ChatBubbleBottomCenterTextIcon className="h-xl w-xl" />,
              position: 'left',
            }}
            onClick={() =>
              window.open(
                'https://my8by23bgby.typeform.com/to/Gg3XiMYY',
                '_blank'
              )
            }
          >
            Send us feedback
          </Button>
        </section>
      </section>
      <p className="whitespace-nowrap pt-l text-body2Light text-neutral-200 l:hidden">
        © {currentYear} EV.com Inc. All rights reserved
      </p>
    </footer>
  );
};

export default FunnelFooter;

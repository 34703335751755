import Icon, { IconIds } from '@/components/atoms/legacy/Icon';
import { useAnalytics } from '@/components/molecules/AnalyticsProvider';
import { BaseButtonProps } from '@/types';
import React, { PropsWithChildren } from 'react';

export enum ButtonVariants {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Tertiary = 'Tertiary',
  Destructive = 'Destructive',
}

export interface ButtonProps extends BaseButtonProps {
  variant?: ButtonVariants;
  loading?: boolean;
  theme?: 'light' | 'dark';
  size?: 'small' | 'default';
}

const Button: React.FC<PropsWithChildren<ButtonProps>> = ({
  variant = ButtonVariants.Primary,
  loading = false,
  children,
  icon,
  theme = 'light',
  onClick = () => {},
  analyticsEvent,
  size = 'default',

  ...props
}) => {
  const { handleActionTracking } = useAnalytics();
  let wrapperClassName = `group relative border-box flex flex-grow inline-flex ${size === 'small' ? 'text-body2Medium py-s rounded-xsmall' : 'text-body1Medium py-[13px] rounded-small'} ${icon ? 'px-l' : 'px-xl'} items-center justify-center cursor-default w-full`;

  switch (variant) {
    case ButtonVariants.Secondary:
      wrapperClassName = `${wrapperClassName} border`;
      if (theme === 'light') {
        if (props.disabled || loading) {
          wrapperClassName = `${wrapperClassName} text-neutral-600 border-neutral-600 opacity-[0.4]`;
        } else {
          wrapperClassName = `${wrapperClassName} text-brandMidnight hover:text-brandMidnight-hover border-brandMidnight hover:border-brandMidnight-hover cursor-pointer`;
        }
      } else if (props.disabled || loading) {
        wrapperClassName = `${wrapperClassName} text-neutral-600 border-neutral-600 opacity-[0.4]`;
      } else {
        wrapperClassName = `${wrapperClassName} text-white border-white cursor-pointer hover:text-brandMidnight-hover hover:border-brandElectric-hover hover:bg-brandElectric-hover`;
      }
      break;
    case ButtonVariants.Tertiary:
      wrapperClassName = `${wrapperClassName} border`;
      if (props.disabled || loading) {
        wrapperClassName = `${wrapperClassName} text-neutral-600 bg-neutral-400`;
      } else {
        wrapperClassName = `${wrapperClassName} text-brandMidnight hover:text-brandMidnight-hover bg-brandElectric border-brandElectric hover:bg-brandElectric-hover hover:border-brandElectric-hover cursor-pointer`;
      }
      break;
    case ButtonVariants.Destructive:
      if (props.disabled) {
        wrapperClassName = `${wrapperClassName} text-neutral-600 bg-red-light`;
      } else if (loading) {
        wrapperClassName = `${wrapperClassName} text-red-medium bg-red-light`;
      } else {
        wrapperClassName = `${wrapperClassName} text-neutral-0 bg-red-medium cursor-pointer`;
      }
      break;
    default: // ButtonVariants.Primary
      if (theme === 'dark') {
        if (props.disabled) {
          wrapperClassName = `${wrapperClassName} border border-neutral-600 text-neutral-600 bg-transparent`;
        } else if (loading) {
          wrapperClassName = `${wrapperClassName} border border-brandElectric text-neutral-400 bg-transparent`;
        } else {
          wrapperClassName = `${wrapperClassName} text-brandMidnight bg-brandElectric hover:text-brandMidnight-hover hover:bg-brandElectric-hover cursor-pointer`;
        }
      } else if (props.disabled || loading) {
        wrapperClassName = `${wrapperClassName} text-neutral-600 bg-neutral-400`;
      } else {
        wrapperClassName = `${wrapperClassName} text-neutral-0 bg-brandMidnight hover:bg-brandMidnight-hover cursor-pointer border-brandMidnight hover:border-brandMidnight-hover border`;
      }
      break;
  }

  return (
    <button
      className={wrapperClassName}
      onClick={(e) => {
        if (analyticsEvent) {
          handleActionTracking(analyticsEvent);
        }
        onClick(e);
      }}
      {...props}
    >
      <div
        className={`inline-flex items-center justify-center gap-s whitespace-nowrap`}
      >
        {icon && icon.position === 'left' && icon.icon}
        {children}
        {icon && icon.position !== 'left' && icon.icon}

        {loading && (
          <div className="absolute animate-spin">
            <Icon
              iconId={IconIds.Spinner}
              strokeColor={theme === 'dark' ? '#0EDAB5' : '#010044'}
              fillColor="transparent"
              className="h-xl w-xl"
            />
          </div>
        )}
      </div>
    </button>
  );
};

export default Button;

import { useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import {
  AuthContext,
  AuthContextType,
} from '@/components/molecules/AuthProvider';
import { AFTER_LOGIN_REDIRECT_KEY } from '@/lib/constants';

export type UseAuthOptions = {
  redirectWithAuthentication?: string;
  redirectWithoutAuthentication?: string;
};

export const useAuth = (
  opt?: UseAuthOptions
): Omit<AuthContextType, 'loading'> => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  const { user, loading, setUserAttribute, updateUserAttributes, signOut } =
    context;
  const router = useRouter();

  useEffect(() => {
    if (user) {
      const redirection = sessionStorage.getItem(AFTER_LOGIN_REDIRECT_KEY);
      if (redirection) {
        sessionStorage.removeItem(AFTER_LOGIN_REDIRECT_KEY);
        router.replace(redirection);
      } else if (opt?.redirectWithAuthentication && !loading) {
        router.push(opt.redirectWithAuthentication);
      }
    } else if (!user && opt?.redirectWithoutAuthentication && !loading) {
      router.push(opt.redirectWithoutAuthentication);
    }
  }, [user, router, loading, opt]);

  return {
    user,
    setUserAttribute,
    updateUserAttributes,
    signOut,
  };
};
